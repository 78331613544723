import React from "react";
import { InputLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";

const bases = [
  {
    value: 2,
    label: "Binaire",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "Octale",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "Décimale",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "Hexadécimale",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 21,
    label: "21",
  },
  {
    value: 22,
    label: "22",
  },
  {
    value: 23,
    label: "23",
  },
  {
    value: 24,
    label: "24",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 26,
    label: "26",
  },
  {
    value: 27,
    label: "27",
  },
  {
    value: 28,
    label: "28",
  },
  {
    value: 29,
    label: "29",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 31,
    label: "31",
  },
  {
    value: 32,
    label: "32",
  },
  {
    value: 33,
    label: "33",
  },
  {
    value: 34,
    label: "34",
  },
  {
    value: 35,
    label: "35",
  },
  {
    value: 36,
    label: "36",
  },
];

export default function App() {
  const [number, setNumber] = React.useState("");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(event.target.value);
  };

  const [base, setBase] = React.useState("10");
  const handleChange1 = (event: SelectChangeEvent) => {
    setBase(event.target.value);
    console.log(event.target.value);
  };

  const [base2, setBase2] = React.useState("16");

  const handleChange2 = (event: SelectChangeEvent) => {
    setBase2(event.target.value);
    console.log(event.target.value);
  };

  function convertirBase(
    nombre: string,
    baseDepart: number,
    baseArrivee: number
  ): string {
    let caracteres = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+/";

    // On vérifie que le nombre est bien écrit dans la base de départ
    if (
      nombre.split("").some((char) => caracteres.indexOf(char) >= baseDepart)
    ) {
      return "Nombre invalide";
    } else if (nombre.split("").some((char) => char === ".")) {
      return "Nombre invalide";
    }

    let nbr = parseInt(nombre, baseDepart);
    return nbr.toString(baseArrivee);
  }

  return (
    <React.Fragment>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "5rem",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontFamily: "Outfit",
            fontWeight: 700,
            letterSpacing: ".1rem",
            fontSize: "1.2rem",
          }}
          gutterBottom
        >
          Convertir un nombre de n'importe quelle base vers n'importe quelle
          base.
        </Typography>

        <TextField
          onChange={handleInputChange}
          id="standard-helperText"
          label="Entrer un nombre"
          variant="standard"

          helperText="Le nombre doit être écrit dans la base de départ et ne doit pas contenir de virgule"
          sx={{ marginTop: "1rem" }}
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" sx={{ marginTop: "1rem" }}>
            Base Depart
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={base}
            label="Base Depart"
            onChange={handleChange1}
            sx={{ marginTop: "1rem" }}
          >
            {bases.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" sx={{ marginTop: "1rem" }}>
            Base Arrivee
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={base2}
            label="Base Arrivee"
            onChange={handleChange2}
            sx={{ marginTop: "1rem" }}
          >
            {bases.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* result */}
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            alignItems: "stretch",
            padding: "5px",
            fontFamily: "Outfit",
            borderRadius: "1rem",
            backgroundColor: "black",
            height: "3rem",
            color: "white",
            fontWeight: 700,
            letterSpacing: ".1rem",
            fontSize: "16px",
            marginTop: "1rem",
          }}
          gutterBottom
        >
          Resultat :{" "}
          {convertirBase(number, parseInt(base), parseInt(base2)) === "NaN"
            ? `S'il vous plait, entrez un nombre valide dans la base ${base}`
            : convertirBase(number, parseInt(base), parseInt(base2))}
        </Typography>
      </Container>
    </React.Fragment>
  );
}
