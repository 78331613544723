import React from 'react';
import './App.css';
import Nav from './components/appBar';
import Calc from './components/calc';
import Button from '@mui/material/Button';
import sbe3 from './img/sbe3.jpg';

var clicks = 0;


const handleDisplayBackGround = () => {
  clicks += 1;
   if (clicks % 2 === 0) {
    document.body.style.backgroundImage = 'none';
  }
  else {
    document.body.style.backgroundImage = `url(${sbe3})`; 
    document.body.style.backgroundRepeat = 'no-repeat';
    // change color of text
  }
}


function App() {

  
  
  return (
    <div className="App">
      <header className="App-header">
        <Nav />
        <Calc />
      </header>

      <Button id='sbe3' onClick={handleDisplayBackGround} >
        Sbe3
      </Button>

    </div>
  );
}

export default App;
